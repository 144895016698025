._2z81rb2 {
  left: 50%;
  top: 50%;
  transform: translate(0%, -50%);
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}
._1ikkqq11 {
  top: 100%;
  width: 200%;
  transform: translateX(-50%);
  left: 50%;
}
._14jd20g0 {
  padding-top: 4px;
  grid-column: 1 / -1;
}
._14jd20g2 {
  width: calc(18% - 40px);
  flex: 1 1;
  margin-right: 40px;
}
._14jd20g3 {
  width: calc(12.5% - 40px);
  margin-right: 40px;
}
._14jd20g5 {
  width: calc(50% / 3 - 4px);
  margin-right: 4px;
}
._14jd20g6 {
  width: calc(20% - 4px);
  margin-right: 4px;
}
._14jd20g7 {
  width: calc(25% - 4px);
  margin-right: 4px;
}
._14jd20g8:hover, ._14jd20g8[data-hover] {
  font-weight: var(--_1ugb2tg3l);
}
._14jd20g1 :last-child {
  margin-right: 0;
}
._14jd20g3 + ._14jd20g4 {
  margin-left: auto;
}
._18h9der2 {
  grid-column: 1 / -1;
  height: var(--_1ugb2tg69);
  transition: background-color 0.4s ease;
  margin-top: var(--_1ugb2tg6b);
}
@media screen and (min-width: 1020px) {
  ._18h9der2 {
    margin-top: var(--_1ugb2tg6a);
  }
}
.p2ug551 {
  grid-column: 1 / -1;
}
.p2ug553 {
  min-height: 30px;
}
.p2ug555 {
  top: 50%;
  right: 0;
  transform: translate3d(0, -50%, 0);
}
@media screen and (min-width: 760px) {
  .p2ug555 {
    right: 10px;
  }
}
._3wyep62 {
  right: 0;
  bottom: 65px;
}
._3wyep64 {
  border-radius: 100%;
  height: 54px;
  width: 54px;
}
._3wyep60 ._3wyep64 {
  display: none;
}
@media screen and (min-width: 760px) {
  ._3wyep62 {
    right: 20px;
  }
}
._1hvx34b0 {
  bottom: 20px;
  left: 20px;
  position: fixed;
  background-color: white;
  z-index: 40;
  padding: 10px;
}
._1hvx34b1 {
  opacity: 0;
  pointer-events: none;
}
@font-face {
font-family: '__riposte_070e4f';
src: url(/_next/static/media/a3c5e5679625bc9a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__riposte_070e4f';
src: url(/_next/static/media/458951d9cee69835-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}@font-face {font-family: '__riposte_Fallback_070e4f';src: local("Arial");ascent-override: 93.14%;descent-override: 19.08%;line-gap-override: 0.00%;size-adjust: 106.94%
}.__className_070e4f {font-family: '__riposte_070e4f', '__riposte_Fallback_070e4f'
}.__variable_070e4f {--font-riposte: '__riposte_070e4f', '__riposte_Fallback_070e4f'
}

@font-face {
font-family: '__spezia_12f0f4';
src: url(/_next/static/media/179ee41d57db404e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__spezia_Fallback_12f0f4';src: local("Arial");ascent-override: 115.81%;descent-override: 29.42%;line-gap-override: 24.23%;size-adjust: 82.64%
}.__className_12f0f4 {font-family: '__spezia_12f0f4', '__spezia_Fallback_12f0f4';font-weight: 600;font-style: normal
}.__variable_12f0f4 {--font-spezia: '__spezia_12f0f4', '__spezia_Fallback_12f0f4'
}

#chat-button {
  display: none;
}
#axeptio_overlay {
  z-index: 89000;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  -webkit-tap-highlight-color: transparent;
}
ol, ul {
  list-style: none;
}
em {
  font-style: italic;
}
blockquote, q {
  quotes: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: none;
}
a {
  text-decoration: none;
  color: inherit;
}
select {
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
}
*:focus-visible {
  outline: none;
}
._1yv3hd61 {
  bottom: 20px;
  right: 20px;
}
._1yv3hd65 {
  top: 10px;
  right: 10px;
}
._1yv3hd66 {
  font-weight: var(--_1ugb2tg3m);
}
._1yv3hd68 {
  color: currentColor;
}
